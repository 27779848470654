import React from 'react';
import Helmet from 'react-helmet';

import {
  BUTTON_STYLE,
  BREAKPOINT,
  COLOR
} from '@latitude/core/utils/constants';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { Link } from '@latitude/link';
import Section from '@latitude/section';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import SvgInline from '@latitude/svg-inline';
import { FeaturesSlider } from '@latitude/features-slider';
import { Box } from '@latitude/box';

import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import { Strong } from '@/components/Text/Bold';

import Layout from '@/components/layout';
import { Text } from '@/components/Text';
import CarouselHeader from '@/components/Carousel/carouselHeader';

import PageData from '../../../data/pages/credit-cards/index.json';
import heroImage from './images/buyers-edge-banner.webp';

import RatesAndFees from './_rates-and-fees';

import PaymentOptions, {
  PaymentOptionsColumn,
  PaymentOptionIcon,
  PaymentOptionTitle,
  PaymentOptionDescription,
  PaymentOptionImages,
  PaymentOptionButtons,
  PaymentOptionNote,
  PaymentOptionCallout,
  PaymentOptionList,
  PaymentOptionListItem,
  PaymentOptionHeading
} from '@/components/PaymentOptions/PaymentOptions';

import BrandedCallout from '@latitude/callout/BrandedCallout';
import PromoInterestFree from './_promo-interest-free';

import pageData from './buyers-edge-data.json';
import FeeChangesCCSection from '../_feeChanges';

const APPLY_URL = getUrlFromEnv('funnel-start-gemvisa');
const HEADER_ITEMS = [
  {
    title: 'Buyer’s Edge',
    text:
      'The Buyer’s Edge card is no longer taking new applications. Lucky for you, we have plenty of other credit cards that were made for shopping. Explore them all to find one that’s right for you.',
    termsconds: '',
    headerImage: heroImage,
    urlFindOutMore: '',
    applyButtonText: 'All Credit Cards',
    urlApplyNow: APPLY_URL
  }
];

function BuyersEdgePage(props) {
  return (
    <Layout location={props.location}>
      <main
        className="navigation-spacer"
        css={`
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Helmet>
          <link rel="canonical" href="/credit-cards/" />
          <title>Buyer's Edge | Latitude Financial</title>
          <meta name="description" content={PageData.metaDesc} />
        </Helmet>

        <CarouselHeader HEADER_ITEMS={HEADER_ITEMS} />

        <div
          className="d-none d-lg-block"
          css={`
            position: relative;
            z-index: 10;
          `}
        >
          <StickyNavigationBranded
            items={pageData.nav}
            offsetElem="[data-sticky-navigation-offset]"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              location: 'sticky'
            }}
            trackId="sticky-get-started"
          />
        </div>

        <FeeChangesCCSection
          heading="Notice of Variation to your Buyer’s Edge credit contract"
          body={
            <>
              Effective from 31st Oct 2024, <b>EFTPOS</b> functionality is being
              removed from your credit card account and will not be available at
              point-of-sale and ATM withdrawals. References to <b>EFTPOS</b>{' '}
              have been removed in your Conditions of Use Booklet.{' '}
              <b>Cash advances</b> will still be available where a{' '}
              <b>BPAY payment</b> is treated as a <b>cash advance</b>. These
              changes will not impact your ability to make purchases at
              participating retailers, with an interest free payment plan
              subject to terms and conditions.
            </>
          }
          link="/eftpos-removal-faq/"
        />

        <Box
          backgroundColor={COLOR.GREY6}
          id="why-us"
          css={`
            position: relative;
            z-index: 1;
          `}
        >
          <FeaturesSlider
            newBrand
            heading="Already using Buyer's Edge? Don't forget these great features."
            data={[
              {
                icon: 'icon-great-offers',
                title: 'Make it yours, Interest Free.',
                text:
                  'Shop today with more time to pay on an Interest Free plan at thousands of <a href="/interest-free/find-a-store/">retailers</a> online and in-store.'
              },
              {
                icon: 'icon-stay-on-track',
                title: 'Managing your account made easy.',
                text:
                  'The <a href="/mobile-app/">Latitude App</a> makes it easier than ever to track spending and manage repayments.'
              }
            ]}
          />
        </Box>

        <HorizontalRule />
        <RatesAndFees />
        <PromoInterestFree />
        <Section
          heading="Payment Options"
          id="payment-options"
          className="section--payment-options"
          css={`
            && {
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-bottom: 72px;
              }
            }
          `}
        >
          <PaymentOptions
            data={[
              {
                id: 'latitude-app',
                title: 'Latitude App',
                titleHtml: 'Latitude App',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-latitude-app" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Latitude App</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Make repayments in just a few taps with our mobile app.
                      </PaymentOptionDescription>
                      <PaymentOptionImages>
                        <Link
                          href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-itunes-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-app-store.svg')
                                .default
                            }
                            height="48px"
                            alt="app store"
                          />
                        </Link>
                        <Link
                          href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-googleplay-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-google-play.svg')
                                .default
                            }
                            height="48px"
                            alt="play store"
                          />
                        </Link>
                      </PaymentOptionImages>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Get the app</Strong>
                          <Text fontSize="14px">
                            Simply download the Latitude App from the App Store
                            or Google Play
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Create a Latitude ID</Strong>
                          <Text fontSize="14px">
                            Open the app and create your Latitude ID (this is
                            different to your Online Service Centre login).
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Link your card</Strong>
                          <Text fontSize="14px">
                            Once your credit cards are linked, you’ll be able to
                            make payments via bank transfer with your BSB and
                            Account Number (Australia only).
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionNote>
                        <Text fontSize="14px">
                          <Strong>Note:</Strong> Please be aware that bank
                          transfer payments can take three business days to
                          clear and you can only make one payment to your card
                          account every five days.
                        </Text>
                      </PaymentOptionNote>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via direct
                          bank transfer on the Latitude App.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'online-service-centre',
                title: 'Latitude Service Centre',
                titleHtml: 'Latitude Service Centre',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-online" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        Latitude Service Centre
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        One of the quickest &amp; easiest way to make
                        repayments.
                      </PaymentOptionDescription>
                      <PaymentOptionButtons css="justify-content:center;">
                        <Link
                          target="_blank"
                          button="secondary"
                          href="https://servicecentre.latitudefinancial.com.au/login"
                          data-trackid="payment-options-service-center-login-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Login
                        </Link>
                        <Link
                          target="_blank"
                          button="tertiary"
                          href="https://servicecentre.latitudefinancial.com.au/register"
                          data-trackid="payment-options-service-center-register-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Register
                        </Link>
                      </PaymentOptionButtons>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Login to the Latitude Service Centre</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Click ‘Pay My Account’</Strong>
                          <Text fontSize="14px">
                            Then enter your bank details.
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                      <PaymentOptionHeading>Registering</PaymentOptionHeading>
                      <Text fontSize="14px">
                        Registering is easy and only takes a few minutes, just
                        make sure you have your card or 16-digit account number
                        on hand.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> Please be aware that payments can
                        take 3 to 5 business days to clear.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via the
                          Latitude Service Centre.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'bpay',
                title: 'BPAY',
                titleHtml:
                  'BPAY<sup style="font-size: 0.75em; top: -0.5em;">&reg;</sup>',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-bpay" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        BPAY<sup>®</sup>
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Available 24 hours a day, seven days a week.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Quote the Biller code</Strong>
                          <Text fontSize="14px">
                            Buyer’s Edge biller code (8714)
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Your account number</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>The amount you wish to pay</Strong>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> If you’re paying by BPAY on your
                        due date, you must complete the transaction before your
                        bank’s cut off time, otherwise the transaction may be
                        processed on the following day. Some BPAY payments can
                        take up to three days to appear on your account.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>Fees</Strong>
                        <Text fontSize="14px">
                          A payment handling fee of $1.95 applies for all
                          payments made via BPAY<sup>&reg;</sup> to pay your
                          account.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'direct-debit',
                title: 'Direct Debit',
                titleHtml: 'Direct Debit',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-direct-debit" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Direct Debit</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Easily make recurring repayments.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn longColumn>
                      <Text>
                        The easiest (and quickest) way to pay off your credit
                        card is with a <strong> monthly direct debit</strong>.
                      </Text>
                      <br />
                      <Text>
                        You can choose whether to pay a set amount, the monthly
                        minimum or the full closing balance, and on your due
                        date each month your payment will automatically happen!
                      </Text>
                      <br />
                      <Text>
                        <Link href="https://latitudefs.zendesk.com/hc/en-au/articles/6027991389329-Direct-Debit-Cards">
                          Click here to find out more about setting up a direct
                          debit.
                        </Link>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              }
            ]}
            // tabContentMinHeight="385px"
            css={`
              && {
                .payment-options-tabs__content {
                  padding: 32px;
                }
              }
            `}
          />
        </Section>

        <BrandedCallout
          line1={
            <React.Fragment>
              <span>For FAQs, useful information and quick and easy forms</span>
              <br />
            </React.Fragment>
          }
          cta={
            <React.Fragment>
              <p>
                <Link
                  button={BUTTON_STYLE.SECONDARY_INVERSE}
                  href="https://latitudefs.zendesk.com/hc/en-au"
                  trackId="callout-visit-our-help-centre"
                  trackEventData={{ location: "We're here to help" }}
                >
                  Visit our Help Centre
                </Link>
              </p>
            </React.Fragment>
          }
        />
      </main>
    </Layout>
  );
}

export default BuyersEdgePage;
